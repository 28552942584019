import SectionWrapper from '../../../sections/SectionWrapper';
import { queries } from "../../../../breakpoints";
import styled from 'styled-components';

export const StyledSectionWrapper = styled(SectionWrapper)`
    text-align:center;
`;

export const FormStyles = styled.div`
    display: flex;
    form{
        justify-content: center;
        width:100%;
        font-family: var(--font-body);
        display: flex;
        margin-top:15px;
        flex-flow:column;
        @media(${queries.min_break_md}){
            flex-flow: row;
        }
    }

    .mktoFieldWrap, .mktoFormCol{
        position: relative;
        height: 100%;
    }
    .mktoGutter{
        display:none;
    }
    .mktoField{
        position:relative;
        border: 1px solid rgba(67,41,163,0.19);
        font-weight: var(--fontWeight-light);
        border-radius:4px;
        width:100%;
        padding: 10px;
        margin-bottom: 10px;
        @media(${queries.min_break_md}){
            height:100%;
            width:400px;
            padding: 0 8px;
        }
    }
    &&& .mktoButtonRow{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @media(${queries.min_break_md}){
            justify-content: flex-start;
        }
    }

    &&& .mktoButton{
        @media(${queries.min_break_md}){
            margin-top:0;
            margin-left:10px;
        }
    }

    &&& .mktoError {
        position: absolute;
        width: 100%;
        color: red;
        font-weight: var(--fontWeight-light);
        right: 0!important;
        bottom: -25px !important;
        font-size: var(--fontSize-0);
        text-align:left;
    }
     // Validation styles
    .mktoInvalid{
        border-color:#C3363E;
    }
    .mktoErrorMsg{
        font-family: var(--fontFamily-body);
        color:#C3363E;
        font-size: 12px;
    }

    .mktoValid{
        border-color: #13AA37;
    }
`;