import { FormStyles, StyledSectionWrapper } from './BlogSubscribeForm.styles';

import MarketoForm from '../../../global/MarketoForm';
// import PropTypes from 'prop-types';
import React from 'react';
import MarketoFormMediator from "../../../global/MarketoFormMediator";

const BlogSubscribeForm = (props) => (
	<StyledSectionWrapper backgroundColor="#F6F6F6">
		<h2>Stay up to date</h2>
		<p>Sign up to receive email updates and to hear what's going on with our company!</p>
		<FormStyles>
			<MarketoFormMediator
				id={[1687]} // prod form 1687, test form 1899
				replicate={true}
				labelBellow={false}
				confirmMessage="Thanks for subscribing!"
				eventCategory="blogs page interaction"
				eventAction="subscribe_cta"
			/>
		</FormStyles>
	</StyledSectionWrapper>
);

BlogSubscribeForm.propTypes = {
	// bla: PropTypes.string,
};

BlogSubscribeForm.defaultProps = {
	// bla: 'test',
};

export default BlogSubscribeForm;
