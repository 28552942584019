import { stripHtmlTags, unescapeHTML } from "../../../../../helpers"

import BackgroundImage from 'gatsby-background-image'
import BlogSubscribeForm from "../BlogSubscribeForm";
import { Footer } from './BlogFooter.styles';
import LinesEllipsis from 'react-lines-ellipsis'
import { Link } from "gatsby"
import React from 'react';
import SectionWrapper from '../../../sections/SectionWrapper';
import parse from "html-react-parser"
import { queries } from '../../../../breakpoints';
import styled from 'styled-components';

const BlogFooter = ({previous, next}) => 
{
	return(
		<Footer>
			<BlogSubscribeForm />
			<SectionWrapper>
				<SectionHeader>More from the blog</SectionHeader>
				<nav className="blog-post-nav">
					<MoreArticles>
						{previous ? (
							<PrevArticle>
								<Link to={`/blog${previous.uri}`} rel="prev">
									<StyledImage fluid={previous?.featuredImage?.node?.localFile?.childImageSharp.fluid} Tag="div" backgroundColor={`white`}/>
									<h6>← {parse(previous.title)}</h6>
									{previous.seo.metaDesc && <TruncatedDesc text={unescapeHTML(stripHtmlTags(previous.seo.metaDesc))} maxLine="2" ellipsis=" ..." trimRight basedOn="words"/>}
								</Link>
							</PrevArticle>
						) :
							<p className="no-more begin">You're at the beginning</p>
						}
						{next ? (
						<NextArticle>
							<Link to={`/blog${next.uri}`} rel="next">
								<StyledImage fluid={next?.featuredImage?.node?.localFile?.childImageSharp.fluid} Tag="div" backgroundColor={`white`}/>
								<h6>{parse(next.title)} →</h6>
								{next.seo.metaDesc && <TruncatedDesc text={unescapeHTML(stripHtmlTags(next.seo.metaDesc))} maxLine="2" ellipsis=" ..." trimRight basedOn="words"/>}
							</Link>
						</NextArticle>
						) : 
							<p className="no-more end">You've reached the end</p>
						}
					</MoreArticles>
				</nav>
			</SectionWrapper>
		</Footer>
	)
};

export default BlogFooter;

const SectionHeader = styled.h2`
  margin-bottom: 40px;
`;

const MoreArticles = styled.div`
  	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	list-style: none;
	align-items: flex-start;
	padding: 0;
	position:relative;
	.no-more{
		align-self:center;
		flex:1;
		&.end{
			text-align:right;
		}
	}
`;

const PrevArticle = styled.div`
	flex:1;
	padding:0 25px 0 0;
	height:100%;
	a{
		display:flex;
		flex-flow:column;
	}
`;	

const NextArticle = styled(PrevArticle)`
	padding:0 0 0 25px;
	a{	
		display:flex;
		flex-flow:column;
		justify-content: flex-end;
	 	text-align:right;
		 align-items:flex-end;
	}
`;

const StyledImage = styled(BackgroundImage)`
	height: 150px;
	width: 100%;
	max-width: 250px;
	border-radius: 4px;
	&:before, &:after{
		border-radius: 4px;

	}
	margin-bottom: 20px;
`;

export const TruncatedDesc = styled(LinesEllipsis)`
    color: black;
    margin-bottom:20px;
    @media(${queries.max_break_lg}){
        display:none;
    }
`;
