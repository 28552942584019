import { GHPackageImage } from "../../../../static/images/svgs/GHPackageImage.jsx";
import { GHComputerImage } from "../../../../static/images/svgs/GHComputerImage.jsx";
import { GHFullPackage } from "../../../../static/images/svgs/GHFullPackage.jsx";

export const InlineCTASlides = [
    {
        image: GHPackageImage(),
        title: "Ready to make your hungry team happy?",
        formfields: [
            {
                label: 'First Name',
                value: "",
                mktoFieldName: "firstName",
            },
            {
                label: 'Last Name',
                value: "",
                mktoFieldName: "lastName",
            },
            {
                label: 'Company Name',
                value: "",
                mktoFieldName: "company",
            },
            {
                label: 'ZIP Code',
                value: "",
                mktoFieldName: "postalCode",
            },
        ],
        buttonText: "Next",
    },
    {
        image: GHComputerImage(),
        title: "Thanks! Whats the best way to get in touch?",
        formfields: [
            {
                label: 'Phone Number',
                value: "",
                mktoFieldName: "phone",
            },
            {
                label: 'Email Address',
                value: "",
                mktoFieldName: "email",
            },
        ],
        ButtonText: "Next"
    },
    {
        image: GHFullPackage(),
        title: "Get excited!",
        subtext: "A member of our team will be reaching out to you shortly.",
    },
];