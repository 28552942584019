// This function removes the style attributes from form elemets so that we can use our own styling and not be constrained by the Marketo default styling.
export function destyleMktoForm(mktoForm, moreStyles) {
    let formEl = mktoForm.getFormElem()[0],
        arrayify = getSelection.call.bind([].slice);

    // remove element styles from <form> and children
    let styledEls = arrayify(formEl.querySelectorAll("[style]")).concat(formEl);
    styledEls.forEach(function (el) {
        el.removeAttribute("style");
    });

    // disable remote stylesheets and local <style>s
    let styleSheets = arrayify(document.styleSheets);
    styleSheets.forEach(function (ss) {
        if (['mktoForms2BaseStyle', 'mktoForms2ThemeStyle'].indexOf(ss.ownerNode.id) !== -1 || formEl.contains(ss.ownerNode)) {
            ss.disabled = true;
        }
    });

    if (!moreStyles) {
        formEl.setAttribute("data-styles-ready", "true");
    }
};

// This function creates a fake form based on the fields that marketo creates. This allows for more flexibility regarting what fields are displayed and when and also allows us to manipulate the fields in greater detail. It also allows us to use multiple marketo forms inside our React components, since the real forms are loaded, but hidden, and the forms that we show are react components and can be handled as such.
export const createFakeMarketo = ({mktoFormId, customFormId, form, replicate}) => {
    const handleFocusOut = (event) => {
        let valuesObj = {},
            formInst = event.target.closest('form'),
            formId = (formInst) ? parseInt(formInst.getAttribute("data-form-id")) : mktoFormId;

        valuesObj[event.target.name] = event.target.value;
        window.MktoForms2.getForm(formId[0])?.setValuesCoerced(valuesObj);
    }

    if (customFormId) {
        document.getElementById(customFormId)?.querySelectorAll('input:not([type="hidden"])').forEach((elem) => elem.onblur = handleFocusOut);
    }
    let realForm = form.getFormElem()[0],
        fakeFormWrapper = document.getElementById('fake_form_' + form.getId()),
        formRows = [...form.getFormElem()[0].querySelectorAll('.mktoFormRow')].reverse();

    if (!fakeFormWrapper) {
        return false;
    }

    realForm.classList.remove('show-preloader');
    fakeFormWrapper.setAttribute('class', form.getFormElem()[0].getAttribute('class'));
    fakeFormWrapper.classList.remove('hidden-form');
    if (!replicate) {
        fakeFormWrapper.classList.add('hidden-form');
    }

    fakeFormWrapper.querySelectorAll('.mktoFormRow').forEach((elem) => elem.remove());
    for (const element of formRows) {
        let clonedRow = element.cloneNode(true);
        clonedRow.querySelectorAll('input').forEach((elem) => elem.onblur = handleFocusOut);
        fakeFormWrapper.prepend(clonedRow);
    }
};

// this function removes any marketo forms and scripts that are running on the page. This is helpful in resetting any Marketo forms and scripts, should such a need arise.
export const removeMarketoScriptAndForms = (marketoScriptId, mktoFormId) => {
    let marketoScript = document.getElementById(marketoScriptId);
    if (marketoScript) {
        marketoScript.remove();
        marketoScript = null;
        window.MarketoMediator_Globals.scriptLoading = false;
        window.MarketoMediator_Globals.scriptIsLoaded = false;
        window.MarketoMediator_Globals.formLoading = false;
    }
    let marketoForm = document.getElementById(`fake_form_${mktoFormId}`);
    if (marketoForm) marketoForm.remove();
}