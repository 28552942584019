// We're using Gutenberg so we need the block styles
import "@wordpress/block-library/build-style/style.css"
import "@wordpress/block-library/build-style/theme.css"
import formatFaqList from "../components/faqHelper"
import { Helmet } from 'react-helmet';

import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";

import BackgroundImage from 'gatsby-background-image'
import BlogFooter from "../components/page-specific/Blog/BlogFooter";
import Layout from "../components/global/Layout";
import React, { useState, useEffect } from "react";
import Seo from 'gatsby-plugin-wpgraphql-seo';
import { graphql } from "gatsby"
import parse from "html-react-parser"
import styled from 'styled-components';
import InlineCTA from "../components/global/InlineCTA";
import BlogCarousel from "../components/page-specific/Blog/BlogCarousel";

const BlogPostTemplate = ({ data: { previous, next, post, allWpPosts }, location }) => {

  const [pageURL, setPageURL] = useState('');
  useEffect(() => {
    if(typeof window !== undefined) {
      setPageURL(window.location.href);
    }
  })

  let seoPostRawData = post.seo.schema.raw.replaceAll('item":"', 'item":"https://corporate.grubhub.com');
  post.seo.schema.raw = seoPostRawData;

  const tags = post.tags.nodes;

  const featuredImage = {
    wpImage: post.featuredImage?.node?.mediaItemUrl,
    fluid: post.featuredImage?.node?.localFile?.childImageSharp?.fluid,
    alt: post.featuredImage?.node?.alt || ``,
  }

  let faqs;

  const faqs1 = [
    {
      header: "What is a virtual lunch and learn?",
      description: "A virtual lunch and learn is an opportunity to digitally share information that you want to get across. It could be thought leadership, informal training, personal development, or a sales presentation."
    },
    {
      header: "Why host a virtual lunch and learn?",
      description: "A virtual lunch and learn is an effective way to foster connections with any audience virtually, including employees, sales prospects, and clients."
    },
    {
      header: "Do I need to provide the lunch for virtual lunch and learns?",
      description: "While you are not required to provide lunch at virtual lunch and learns, we highly recommend you do! Partner with a corporate ordering solution to easily provide meals for attendees, no matter where they’re dialing in from."
    }
  ]

  const faqs2 = [
    {
      header: "What is a virtual holiday party?",
      description: "A virtual holiday party is an end of year celebration event that brings groups of people together online through activities, music and food."
    },
    {
      header: "Why conduct a holiday party virtually?",
      description: "With the recent trend of employees shifting to working remotely or in a hybrid fashion, a virtual holiday party is a great way to include everyone, regardless of their working style or schedule."
    },
    {
      header: "What are the benefits of a virtual holiday party?",
      description: "Virtual holiday parties are a great way to bring employees together and celebrate the accomplishments of a given year, ultimately helping boost employee morale."
    }
  ]

  const faqs3 = [
    {
      header: "What is a culture-building event?",
      description: "A culture-building, or team-building event, is an experience that members of a team participate in, such as an activity, game or a meal, in order to help foster collaboration and boost employee morale."
    },
    {
      header: "Can culture-building events be done to the same effect virtually?",
      description: "Yes, they can! There are a variety of formats and activities for you to choose from in order to have a successful virtual culture-building event."
    },
    {
      header: "Why should I host a virtual culture-building event?",
      description: "Culture-building events improve employee morale, productivity and creativity. By conducting a culture-building event virtually, you are able to include all hybrid and remote employees, regardless of their working style or schedule."
    }
  ]

  const faqs4 = [
    {
      header: "What is an employee lunch program?",
      description: "An employee lunch program is a company-provided benefit to employees. This could be in-office catering or a program where employees receive an individual stipend to order food from their favorite restaurants."
    },
    {
      header: "Why should my company implement a team lunch program?",
      description: "A team lunch program is a simple and easy way to fuel productivity within your team and show your value for their hard work. A Grubhub survey found that 60% of employees equate company-provided food with feeling more valued and appreciated."
    },
    {
      header: "How is an employee lunch program implemented?",
      description: "An employee lunch program can be organized entirely on your own or through the help of a corporate ordering solution such as Grubhub. A Grubhub Corporate Account can help improve your employee meal program with our simple, customizable, and cost-effective platform."
    }
  ]

  const faqs5 = [
    {
      header: "Why should I give my employees a return-to-office gift?",
      description: "A return-to-office gift expresses employee gratitude which is a great way to boost employee morale during a time of transition."
    },
    {
      header: "What makes a good return-to-office gift?",
      description: "A gift that provides your employees with joy, entertainment, or something that makes their transition back to the office a little easier are always appreciated gifts from employees."
    },
    {
      header: "Can food be considered a return to office gift?",
      description: "Yes! Many companies are providing employees with a meal stipend for lunch. A Grubhub survey found that 60% of employees equate company-provided food with feeling more valued and appreciated. "
    }
  ]

  var location = location.pathname
  if(location[location.length -1] != '/') {
    location = location + '/';
  }

  if(location == '/blog/how-to-host-a-virtual-lunch-and-learn/') {
    faqs = faqs1
  } else if (location == '/blog/team-building-activities-for-your-virtual-holiday-party/') {
    faqs = faqs2
  } else if (location == '/blog/boosting-employee-morale-with-culture-building-virtual-events/') {
    faqs = faqs3
  } else if (location == '/blog/3-ideas-to-improve-your-team-lunch-program/') {
    faqs = faqs4
  } else if (location == '/blog/8-gift-ideas-to-support-employees-return-to-the-office/') {
    faqs = faqs5
  }

  const relatedBlogCarousel = post.relatedBlogPostCarousel.relatedBlogPostCarousel;
  const checkIfPostCategoryExists = ( categories ) => {
    var isValid = false;
    categories.forEach( ( category ) => {
      post.categories.nodes.forEach( ( currentCategory ) => {
        if( currentCategory.termTaxonomyId === category.termTaxonomyId ) {
          isValid = true;
          return isValid;
        }
      } );
    } );

    return isValid;
  }

  const autoRelatedPostsCarousel = () => {
    var postsFiltered = [],
        totalPosts = allWpPosts.nodes.length;
    for ( var i = 0; i < totalPosts; i++ ) {
      if( checkIfPostCategoryExists( allWpPosts.nodes[i].categories.nodes ) ) {
        if( postsFiltered.length < parseInt( relatedBlogCarousel.autoPostsNumber ) ) {
          postsFiltered.push( allWpPosts.nodes[i] );
        } else {
          i = totalPosts;
        }
      }
    }
    return postsFiltered;
  };

  var activeRelatedPosts = relatedBlogCarousel && relatedBlogCarousel.addPostCarousel,
      relatedCarouselPosts = activeRelatedPosts && relatedBlogCarousel.postsSelection === 'custom' ? relatedBlogCarousel.postsList : autoRelatedPostsCarousel();

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {faqs && formatFaqList(faqs)}
        </script>
      </Helmet>
      <Layout
        title={parse(post.title)}
        description={post.seo.metaDesc}
        noindex={post.seo.metaRobotsNoindex === "noindex"}
        nofollow={post.seo.metaRobotsNofollow === "nofollow"}
        utag_data={{
          pageGroup: "grubhub - lead generation",
          subGroup: "blog",
          pageName: `post - ${post.title}`,
          brand: "grubhub"
        }}
        image={featuredImage?.wpImage || ""}
        >
        {/* Seo component provided by gatsby-plugin-wpgraphql-seo */}
        <Seo post={post} />
        <article
          itemScope
          itemType="http://schema.org/Article"
          className="corporate-blog-post"
        >
          <header>
            {/* if we have a featured image for this post let's display it */}
            {featuredImage?.fluid && (
            <StyledBackgroundImage
              Tag="section"
              fluid={featuredImage.fluid}
              backgroundColor={`#040e18`}
            >
            </StyledBackgroundImage>
            )}
            <div className="container meta-container">
              <StyledTopPart>
                <Headline itemProp="headline">{parse(post.title)}</Headline>
                <StyledTagsContainer>
                  {tags.map((tag) => {
                    return (
                      <StyledTag key={tag.name} href={`/blog?tagName=${tag.name}`}>{tag.name}</StyledTag>
                    )
                  })}
                </StyledTagsContainer>
              </StyledTopPart>
              {post?.seo?.metaDesc && <p>{parse(post.seo.metaDesc)}</p>}
              <PostMeta>
                <div>
                  <PostAuthor>By <strong>The Grubhub Staff</strong></PostAuthor>
                  <PostDate>Published on {post.date}</PostDate>
                </div>
                <Socials>
                  {/* //! Share buttons will not work in dev environment because it is pulling localhost from location.href */}
                  <FacebookShareButton url={encodeURI(pageURL)}>
                    <FacebookIcon
                      size={32}
                      round={true} />
                  </FacebookShareButton>
                  <LinkedinShareButton url={encodeURI(pageURL)}>
                    <LinkedinIcon
                      size={32}
                      round={true} />
                  </LinkedinShareButton>
                  <TwitterShareButton url={encodeURI(pageURL)}>
                    <TwitterIcon
                      size={32}
                      round={true} />
                  </TwitterShareButton>
                </Socials>
              </PostMeta>

            </div>
          </header>

          {!!post.content && (
            <section itemProp="articleBody" className="container article-body">
              {parse(post.content)}
            </section>
          )}
          {post.showInlinecta.showInlinecta && <InlineCTA />}
          { post.showContactUsButton && post.showContactUsButton.showContactUsButton && (
            <StyledLinkContainer>
              <StyledContactUsLink
                  href='/contact-us/'
              >
                Contact us to learn more
              </StyledContactUsLink>
            </StyledLinkContainer>
          )}

          {
            //  new related blog posts carousel
              activeRelatedPosts && <RelatedBlogPostsContainer>
              <div className={'related-carousel-posts'}>
                {relatedCarouselPosts.length > 0 && <BlogCarousel
                    posts={relatedCarouselPosts}
                    useParentWidth={true}
                />}
              </div>
          </RelatedBlogPostsContainer>}

          <BlogFooter previous={previous} next={next}/>
        </article>
      </Layout>
    </>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    
    allWpPosts: allWpPost(
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        content
        categories {
          nodes {
            id
            name
            termTaxonomyId
          }
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                fixed {
                  src
                }
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      uri
      date(formatString: "MMMM DD, YYYY")
      showInlinecta {
        showInlinecta
      }
      categories {
        nodes {
          id
          name
          termTaxonomyId
        }
      }
      showContactUsButton {
        showContactUsButton
      }
      relatedBlogPostCarousel {
        relatedBlogPostCarousel{
          addPostCarousel
          postsSelection
          autoPostsNumber
          postsList{
            ... on WpPost {
              id
              excerpt
              content
              title
              uri
              date(formatString: "MMMM DD, YYYY")
              categories {
                nodes {
                  id
                  name
                  termTaxonomyId
                }
              }
              featuredImage{
                node{
                  mediaItemUrl
                  altText
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1000, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                      fixed {
                        src
                      }
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
      featuredImage {
        node {
          mediaItemUrl
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      tags {
        nodes {
          id
          name
        }
      }
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
            altText
            sourceUrl
            srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
            altText
            sourceUrl
            srcSet
        }
        canonical
        cornerstone
        schema {
            articleType
            pageType
            raw
        }
      }
    }

    # this gets us the previous post by id (if it exists)
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
      seo {
        metaDesc
      }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    # this gets us the next post by id (if it exists)
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
      seo {
        metaDesc
      }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`


const StyledBackgroundImage = styled(BackgroundImage)`
  height: 500px;
`;

export const RelatedBlogPostsContainer = styled.div`
    display: flex;
    justify-content: center;
    
    .related-carousel-posts{
      display: flex;
      width: 100%;
      max-width: 1120px;
    }
`;

const Headline = styled.h1`
  font-weight: bold;
  margin: 30px 0 15px; 
`;

const PostMeta = styled.div`
  border-top: 2px solid var(--color-primary-dark)20; 
  padding-top: 15px;
  display: flex; 
  flex-flow: row;
  justify-content:space-between;
  align-items: center;
  margin-bottom: 40px;
`;

const PostAuthor = styled.p`
  font-size: var(--fontSize-0);
  margin: 0;
  strong{
    font-size: var(--fontSize-1);
    font-weight: bold;
  }  
`;

const PostDate = styled.p`
  color: #979797;
  font-size: var(--fontSize-0);
  font-weight: 300;
  margin:0;
`;

const Socials = styled.div`
  svg{
    margin:0 5px;
    border-radius: 50px;
    background: #E4E5E6;
    circle {
      transition: all .2s;
      opacity:0;
    }
    &:hover{
      circle{
        opacity:1;
      }
    }
  }
`;

const StyledContactUsLink = styled.a`
  padding: 12px 30px;
  margin: 15px auto;
  border-radius: 100px;
  font-weight: bold;
  font-size: var(--fontSize-1);
  min-width: 175px;
  display: inline-block;
  text-align: center;
  background-color: var(--color-primary);
  color: white;
  font-family: var(--font-body);
  border: none;
  transition: all .1s;
  &:hover {
      color: white;
      text-decoration: none;
      background-color: var(--color-primary-dark);
  }
  &:active{
      background-color: var(--color-primary-darker);
  }
`;

const StyledLinkContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledTopPart = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const StyledTagsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

const StyledTag = styled.a`
  padding: 5px 10px;
  margin: 8px;
  text-decoration: none;
  color: #4a4a4a;
  border-radius: 5px;
  border: 1px solid #4a4a4a;
  font-size: 16px;
  :hover {
    text-decoration: none;
    color: #FFFFFF;
    background-color: #ac243d;
    border: 1px solid #ac243d;
  }
`;
