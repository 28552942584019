import React, { useEffect, useState } from 'react';

import {
  StyledMainContainer,
  StaticContainer,
  StyledImage,
  StyledText,
  StyledSeparator,
  FormContainer,
  FormTitle,
  StyledTextContainer,
  InputContainer,
  InputLabel,
  InputField,
  InputButton,
  ErrorMessage,
  ErrorContainer,
  ButtonContainer,
} from "./InlineCTA.styles.js"
import { InlineCTASlides } from './InlineCTASlides';
import MarketoFormMediator from "../MarketoFormMediator";

// test form ID is 1898
// real form ID is 1671
const marketoFormId = 1671;

const InlineCTA = (props) => {
  const [ pageToDisplay, setPageToDisplay ] = useState(0);
  const [ firstName, setFirstName ] = useState("");
  const [ lastName, setLastName ] = useState("");
  const [ company, setCompany ] = useState("");
  const [ zip, setZip ] = useState("");
  const [ phone, setPhone ] = useState("");
  const [ email, setEmail ] = useState("");
  const [ showError, setShowError ] = useState(false);
  const [ submitActionForMediator, setSubmitActionForMediator ] = useState( false );
  const [ formSubmitted, setFormSubmitted ] = useState(false);
  const [ isStep1Valid, setIsStep1Valid ] = useState(false);
  const [ isStep2Valid, setIsStep2Valid ] = useState(false);

  useEffect (() => {
    if ( firstName !== "" && lastName !== "" && company !== "" && zip !== "" ) setIsStep1Valid(true);
  }, [firstName, lastName, company, zip])

  useEffect(() => {
    if (email !== "" && phone !== "") setIsStep2Valid(true);
  }, [email, phone])

  const submitAction = () => {
    // TRIGGER SUBMIT ON THE MARKETO FORM MEDIATOR
    if (!formSubmitted) {
      setSubmitActionForMediator(marketoFormId);
    }
    setFormSubmitted(true);
  }
  const onMediatorFormSubmit = () =>{
    // DISPLAY THANK YOU PAGE WHEN MARKETO FORM MEDIATOR HAS SUBMITTED THE FORM
    setPageToDisplay( 2 );
  }
  return (
    <StyledMainContainer id="step-form">
      <StaticContainer isAlone={pageToDisplay < 2 ? false : true}>
        <StyledImage>
          { InlineCTASlides[pageToDisplay].image }
        </StyledImage>
        <StyledTextContainer>
          <StyledText>{InlineCTASlides[pageToDisplay].title}</StyledText>
          {
            pageToDisplay === 2 && InlineCTASlides[pageToDisplay].subtext ?
            <StyledText>{InlineCTASlides[pageToDisplay].subtext}</StyledText>:
            null
          }
        </StyledTextContainer>
      </StaticContainer>
      { pageToDisplay < 2 && <StyledSeparator />}
      {
        pageToDisplay === 0 ?
          <FormContainer shouldDisplay={pageToDisplay < 2 ? true : false}>
            <FormTitle width={100} >Get started</FormTitle>
            <InputContainer width={40}>
              <InputLabel>First Name:</InputLabel>
              <InputField
                name={"FirstName"}
                required
                type="text"
                value={firstName}
                onChange={e => {setFirstName( e.target.value ); setShowError(false)}}
              />
            </InputContainer>
            <InputContainer width={60}>
              <InputLabel>Last Name:</InputLabel>
              <InputField
                name={"LastName"}
                required
                type="text"
                value={lastName}
                onChange={e => { setLastName(e.target.value); setShowError(false) }}
              />
            </InputContainer>
            <InputContainer width={70}>
              <InputLabel>Company Name:</InputLabel>
              <InputField
                name={"Company"}
                required
                type="text"
                value={company}
                onChange={e => { setCompany(e.target.value); setShowError(false) }}
              />
            </InputContainer>
            <InputContainer width={30}>
              <InputLabel>ZIP Code:</InputLabel>
              <InputField
                name={"PostalCode"}
                required
                type="number"
                value={zip}
                onChange={e => { setZip(e.target.value); setShowError(false) }}
              />
            </InputContainer>
            <InputButton
              valid={isStep1Valid}
              type="button"
              onClick={() => isStep1Valid && setPageToDisplay(pageToDisplay + 1)}
            >Next</InputButton>
          </FormContainer>:
        pageToDisplay === 1 ?
          <FormContainer shouldDisplay={pageToDisplay < 2 ? true : false}>
            <InputContainer
              width={100}
              className="input-hidden"
            >
              <InputLabel>Phone Number:</InputLabel>
              <InputField
                name={"Phone"}
                required
                type="number"
                value={phone}
                onChange={e => { setPhone(e.target.value); setShowError(false) }}
              />
            </InputContainer>
           <InputContainer width={100}>
              <InputLabel>Phone Number:</InputLabel>
              <InputField
                name={"Phone"}
                required
                type="number"
                value={phone}
                onChange={e => { setPhone(e.target.value); setShowError(false) }}
              />
            </InputContainer>
            <InputContainer width={100}>
              <InputLabel>Email:</InputLabel>
              <InputField
                name={"Email"}
                required
                type="email"
                value={email}
                onChange={e => { setEmail(e.target.value); setShowError(false) }}
              />
            </InputContainer>
            <ErrorContainer showError={showError}>
              <ErrorMessage>Please check the entries and try again.</ErrorMessage>
            </ErrorContainer>
            <ButtonContainer>
              <InputButton
                valid
                type="button"
                onClick={() => { setPageToDisplay(pageToDisplay - 1) }}
              >Previous</InputButton>
              <InputButton
                valid={isStep2Valid}
                type="button"
                onClick={() => {
                  if (isStep2Valid) {
                    if (window.MktoForms2.getForm(marketoFormId)?.validate()) {
                      submitAction();
                    } else {
                      setShowError(true);
                    }
                  } else {
                    setShowError(true);
                  }
                }}
              >Next</InputButton>
            </ButtonContainer>
          </FormContainer>:
          <FormContainer shouldDisplay={pageToDisplay < 2 ? true : false} />
      }
      <MarketoFormMediator
        id={[marketoFormId]}
        replicate={false}
        submitFromStepForm={submitActionForMediator}
        onSubmit={onMediatorFormSubmit}
        customFormId={"step-form"}
      />
    </StyledMainContainer>
  );
}

export default InlineCTA;
