import React, { useCallback, useContext, useEffect, useState } from 'react';

import { AnalyticsContext } from "../../../context";
import PropTypes from "prop-types";
import { MarketoFormMediatorStyle, MarketoFormContainer, FormConfirm, StyledForm, LoadingSpinner, SpinnerContainer, FakeFormWrapper } from "./MarketoFormMediator.style";
import { destyleMktoForm, createFakeMarketo, removeMarketoScriptAndForms } from './MktoMediatorHelperFunctions';

const handleFormSubmitted = (formID) => {
    if (window.MarketoMediator_Globals.formsSubmitted && window.MarketoMediator_Globals.formsSubmitted.length > 0) {
        window.MarketoMediator_Globals.formsSubmitted.push(formID);
    } else {
        window.MarketoMediator_Globals.formsSubmitted = [formID];
    }
}

const MarketoFormMediator = ( props ) => {
    const { onSubmit, confirmMessage, eventAction, eventCategory, labelBellow } = props
    let marketoScriptId = `mktoForms`;
    const mktoFormId = props.id; /// 1785

    const { handleTealiumEvent, pageHistory } = useContext( AnalyticsContext );
    const [ scriptIsLoaded, setScriptIsLoaded ] = useState( false );
    const [ formIsLoaded, setFormIsLoaded ] = useState( false );

    const config = {
        podId: process.env.GATSBY_MARKETO_POD_ID,
        munchkinId: process.env.GATSBY_MUNCHKIN_INIT_VALUE,
        formIds: props.id,
        formTotal: props.id.length,
        formsLoaded: 0,
    }

    const loadScript = useCallback(() => {
        if (window.MarketoMediator_Globals.scriptLoading || window.MarketoMediator_Globals.scriptIsLoaded) {
            return true;
        }
        window.MarketoMediator_Globals.scriptLoading = true;
        let s = document.createElement('script');
        s.id = marketoScriptId;
        s.type = 'text/javascript';
        s.async = true;
        s.src = process.env.GATSBY_MARKETO_SOURCE;
        s.onload = () => setScriptIsLoaded(true);
        s.onreadystatechange = function () {
            if (this.readyState === 'complete' || this.readyState === 'loaded') {
                window.MarketoMediator_Globals.scriptIsLoaded = true;
                window.MarketoMediator_Globals.scriptLoading = false;
                setScriptIsLoaded(true);
            }
        };
        document.getElementsByTagName('head')[0].appendChild(s);
    }, [marketoScriptId]);

    const loadMarketoForm = () => {

        let formIds = window.MarketoMediator_Globals.formLoadingId;
        if (formIds.length > 0) {
            /* util */
            let arrayify = getSelection.call.bind([].slice);
            /* const */
            let MKTOFORM_ID_ATTRNAME = "data-formid";
            let formId = window.MarketoMediator_Globals.formLoadingId.shift();
            /* fix inter-form label bug! */
            window.MktoForms2?.whenRendered(function (form) {
                let formEl = form.getFormElem()[0],
                    rando = "_" + new Date().getTime() + Math.random();

                arrayify(formEl.querySelectorAll("label[for]")).forEach(function (labelEl) {
                    let forEl = formEl.querySelector('[id="' + labelEl.htmlFor + '"]');
                    if (forEl) {
                        labelEl.htmlFor = forEl.id = forEl.id + rando;
                    }
                });
            });
            let loadForm = window.MktoForms2?.loadForm.bind(window.MktoForms2, config.podId, config.munchkinId, formId),
                formEls = arrayify(document.querySelectorAll("[" + MKTOFORM_ID_ATTRNAME + '="' + formId + '"]'));
            loadFormCbs(loadForm, formEls, formId);
            window.MktoForms2?.whenReady(function (form) {
                destyleMktoForm(form);
                let formDivs = document.getElementsByClassName("mktoFieldWrap");
                for (const element of formDivs) {
                    if (labelBellow) {
                        if (element.children.length > 3 && element.children[0].tagName === 'LABEL' && element.children[2].tagName === 'INPUT') {
                            element.insertBefore(element.children[2], element.children[0]); // insert input before label
                        }
                        let inputWithValue = element.children[0];
                        inputWithValue.addEventListener("keyup", function (event) {
                            if (event.target.value.trim() === "") {
                                (event.target).parentElement.classList.remove("valueInside");
                            } else {
                                (event.target).parentElement.classList.add("valueInside");
                            }
                        });
                    }
                }
                createFakeMarketo({
                    mktoFormId: mktoFormId,
                    customFormId: props.customFormId,
                    form: form,
                    replicate: props.replicate
                });
                setFormIsLoaded(true);
                let loadingSpinners = document.getElementsByClassName('marketo-loading-spinner-' + form.getId())
                for (let spinner of loadingSpinners) spinner.remove();
                checkAndLoadForms();
            });
        }
    };

    useEffect(() => {
        if (scriptIsLoaded && config.formsLoaded < config.formTotal && !window.MarketoMediator_Globals.formLoading) {
            window.MarketoMediator_Globals.formLoading = true;
            loadMarketoForm();
        }
        return () => {
            removeMarketoScriptAndForms(marketoScriptId, mktoFormId[0]);
        }
    }, [pageHistory, scriptIsLoaded, props.id, onSubmit, confirmMessage, labelBellow, eventCategory, eventAction, handleTealiumEvent, formIsLoaded]);

    useEffect(() => {
        if (window.MarketoMediator_Globals === undefined) {
            window.MarketoMediator_Globals = {
                scriptLoading: false,
                scriptIsLoaded: false,
                formLoading: false,
                formLoadingId: [...props.id],
                formPropsById: [],
            }
        } else {
            let idFound = false
            for (let id of window.MarketoMediator_Globals.formLoadingId) {
                if (id === props.id[0]) {
                    idFound = true;
                }
            }
            if (!idFound) window.MarketoMediator_Globals.formLoadingId.push(props.id);
        }

        window.MarketoMediator_Globals.formPropsById[props.id[0]] = props;

        //Check if form element exists and either load the form or set isLoaded to true
        if (!window.MarketoMediator_Globals.scriptIsLoaded) {
            loadScript();
        } else if (scriptIsLoaded) {
            window.MarketoMediator_Globals.scriptIsLoaded = true;
            window.MarketoMediator_Globals.scriptLoading = false;
            setScriptIsLoaded(true);
            checkAndLoadForms();
        }
        return () => {
            removeMarketoScriptAndForms(marketoScriptId, mktoFormId[0]);
        }
    }, [loadScript, marketoScriptId]);

    const handleSubmitClick = ( event ) => {
        let formId = parseInt( event.target.getAttribute( 'data-form-id' ) ),
            formInstance = window.MktoForms2.getForm( formId );
        if( event.target.getAttribute('disabled') !== 'disabled' && formInstance !== undefined && formInstance.validate() ) {
            event.target.setAttribute('disabled', 'disabled');
            if (
                !window.MarketoMediator_Globals.formsSubmitted ||
                (window.MarketoMediator_Globals.formsSubmitted && window.MarketoMediator_Globals.formsSubmitted.filter(form => form === formId).length === 0)
            ) {
                formInstance.submit();
                handleFormSubmitted(props.submitFromStepForm);
            }
        } else {
            console.log('Form not valid or disabled!');
        }
    }
    if( props.submitFromStepForm ) {
        if (
            !window.MarketoMediator_Globals.formsSubmitted ||
            (window.MarketoMediator_Globals.formsSubmitted && window.MarketoMediator_Globals.formsSubmitted.filter(form => form === props.submitFromStepForm).length === 0) 
        ) {
            window.MktoForms2.getForm(props.submitFromStepForm).submit();
            handleFormSubmitted(props.submitFromStepForm);
        }
    }

    const loadFormCbs = ( loadForm, formEls, formId ) => {
        if (formEls) {
            let formEl = formEls.shift();
            formEl.id = "mktoForm_" + formId;

            if (loadForm) {
                loadForm(function (form) {
                    formEl.id = "";
                    if (form === null) {
                        return false;
                    }
                    if (formEls.length) {
                        loadFormCbs(formEls);
                    }
                    formAddHiddenFields(form);
                    // Add tracking to the form on validation
                    formOnValidate(form, formId);
                    // Add an onSuccess handler
                    formOnSuccess(form, formId)
                });
            }
        }
    }

    const checkAndLoadForms = () => {
        if( window.MarketoMediator_Globals.formLoadingId.length > 0 ) {
            loadMarketoForm();
        }
    }

    function getCookie(name) {
        let cookie = {};
        document.cookie.split(';').forEach(function(el) {
            let split = el.split('=');
            cookie[split[0].trim()] = split.slice(1).join("=");
        })
        return cookie[name];
    }

    const formAddHiddenFields = ( form ) => {
        let getUtmMedium = getCookie('utm_medium'),
            getUtmSource = getCookie('utm_source'),
            getUtmContent = getCookie('utm_content'),
            getUtmCampaign = getCookie('utm_campaign'),
            getTrafficSourceDetails = getCookie('Referral_Details__c');

        form.addHiddenFields( {
            referringPage: `${pageHistory[0]}`,
            "uTMMedium": getUtmMedium ? getUtmMedium : "",
            "uTMSource": getUtmSource ? getUtmSource : "",
            "uTMContent": getUtmContent ? getUtmContent : "",
            "uTMCampaign": getUtmCampaign ? getUtmCampaign : "",
            "Referral_Details__c": getTrafficSourceDetails ? getTrafficSourceDetails: "",
        } )
    }
    const formOnSuccess = ( form, formId ) => {
        form.onSuccess( () => {
            // get the form's jQuery element and hide it
            form.getFormElem().hide();

            if( document.getElementById( 'payFooterThankYou' ) ) {
                document.getElementById( 'payFooterThankYou' ).style.display = 'block';
            }

            let formConfirm = window.MarketoMediator_Globals.formPropsById[formId].confirmMessage,
                formOnSubmit = window.MarketoMediator_Globals.formPropsById[formId].onSubmit;

            // If the form has a confirmMessage and no onSubmit function sent to it
            if( formConfirm && !formOnSubmit ) {
                let thankYouMessage = document.getElementsByClassName( 'confirmform-' + formId ),
                    realForms = document.querySelectorAll(`[data-formid='${formId}']`);
                if( thankYouMessage.length > 0 ) {
                    thankYouMessage[0].style.display = 'block';
                    if (realForms) {
                        for (let realForm of realForms)
                            realForm.style = 'display: none !important;';
                    }
                }

            } else if( !formConfirm && formOnSubmit ) {
                //Calls the function passed in from props to handle the submit
                formOnSubmit();
            }

            // return false to prevent the submission handler from taking the lead to the follow up url.
            return false;
        } );
    }

    const formOnValidate = ( form, formId ) => {
        form.onValidate( ( status ) => {
            let event = {
                eventcategory: eventCategory,
                eventaction: eventAction,
            }
            if (status) {
                event.eventlabel = "success";
                handleTealiumEvent(event);
            } else {
                event.eventlabel = "error";
                handleTealiumEvent(event);
            }
        } );
    }

    let hiddenMediator = props.replicate ? '' : 'hidden-mediator';

    return (
        <MarketoFormMediatorStyle className={hiddenMediator}>
            <FakeFormWrapper id={`fake_form_${props.id}`} className={"hidden-form"} data-form-id={props.id}>
                <div className={"mktoButton fake-submit-button"} data-form-id={props.id} onClick={handleSubmitClick}>Submit</div>
            </FakeFormWrapper>
            <MarketoFormContainer>
                <StyledForm className="marketo-form marketo-mediator show-preloader" data-formid={props.id[0]}>
                    <SpinnerContainer className={`marketo-loading-spinner-${props.id}`}>
                        <LoadingSpinner/>
                    </SpinnerContainer>
                </StyledForm>
                <FormConfirm className={`confirmform-${props.id}`} style={{ display: 'none' }}>
                    <p>{props.confirmMessage}</p>
                </FormConfirm>
            </MarketoFormContainer>
        </MarketoFormMediatorStyle>
    );
}

export default MarketoFormMediator;

MarketoFormMediator.propTypes = {
    id: PropTypes.array.isRequired,
    replicate: PropTypes.bool,
    labelBellow: PropTypes.bool,
    customFormId: PropTypes.string,
    confirmMessage: PropTypes.string,
    onSubmit: PropTypes.func,
    submitFromStepForm: PropTypes.number,
    eventCategory: PropTypes.string,
    eventAction: PropTypes.string,
};
MarketoFormMediator.defaultProps = {
    id: [],
    replicate: true,
    labelBellow: false,
    customFormId: null,
    confirmMessage: null,
    onSubmit: null,
    submitFromStepForm: false,
    eventCategory: "marketo form interaction",
    eventAction: "submit_cta"
};
