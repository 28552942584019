import styled from 'styled-components';

export const StyledMainContainer = styled.div`
  height: 456px;
  width: 100%;
  max-width: 990px;
  margin: 40px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primary);
  border-radius: 5px;
  @media only screen and (max-width: 885px) {
    border-radius: 0px;
    flex-direction: column;
    height: 100vh;
    max-height: 100vh;
  }
;
`;

export const StaticContainer = styled.div`
  height: 100%;
  width: ${({ isAlone }) => isAlone ? "100%" : "50%" };
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 7.5%;
  @media only screen and (max-width: 885px) {
    padding: 0px 20px;
    width: 100%;
    max-height: 50vh;
  }
`;

export const StyledImage = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledTextContainer = styled.div`
  height: 30%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const StyledText = styled.p`
  font-family: "Scandia Bold", sans-serif;
  font-weight: 700;
  color: white;
  text-align: center;
  font-size: 36px;
  line-height: 41.76px;
  margin: 0px;
`;

export const StyledSeparator = styled.div`
  height: 80%;
  width: 5px;
  background-color: #FFFFFF;
  border-radius: 5px;
  @media only screen and (max-width: 885px) {
    height: 5px;
    width: 80%;
  }
`;

export const FormContainer = styled.div`
  width: ${({ shouldDisplay }) => shouldDisplay ? "50%" : "0%" };
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  @media only screen and (max-width: 885px) {
    width: 100%;
    height: ${({ shouldDisplay }) => shouldDisplay ? "100%" : "0%" };
    flex-direction: column;
  }
`;

export const FormTitle = styled.p`
  color: #FFFFFF;
  width: ${({ width }) => width}%;
  text-align: center;
  font-size: 24px;
  line-height: 27.84px;
  font-family: "Scandia Bold", sans-serif;
  font-weight: 700;
  margin-bottom: 40px;
  @media only screen and (max-width: 885px) {
    margin: 10px 0px;
  }
`;

export const InputContainer = styled.div`
  width: calc(${({ width }) => width}% - 10px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0px 5px 40px 5px;
  @media only screen and (max-width: 885px) {
    margin: 0px 0px 10px 0px;
    width: 100%;
  }
  &.input-hidden{
    display:none;
  }
`;

export const InputLabel = styled.p`
  color: #FFFFFF;
  font-family: "Scandia Bold", sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 13.92px;
  margin: 0px 0px 5px 0px;
`;

export const InputField = styled.input`
  width: 100%;
  height: 48px;
  font-family: "Scandia Bold", sans-serif;
  font-weight: 700;
  font-size: 24px;
  border: none;
  border-radius: 5px;
`;

export const InputButton = styled.button`
  background-color: ${({ valid }) => valid ? "#FF8000" : "#CACACA"};
  color: ${({ valid }) => valid ? "#FFFFFF" : "#F0F0F0"};
  border: none;
  width: 140px;
  height: 47px;
  border-radius: 47px;
  margin: 0px auto;
  @media only screen and (max-width: 885px) {
    margin: 15px 0px 90px 0px;
    width: 100%;
  }
`;

export const ErrorContainer = styled.div`
  width: calc(100% - 10px);
  display: ${({showError}) => showError ? "flex" : "none"};
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0px 5px 40px 5px;
  @media only screen and (max-width: 885px) {
    margin: 0px 0px 10px 0px;
    width: 100%;
  }
  &.input-hidden{
    display:none;
  }
`;

export const ErrorMessage = styled.p`
  color: red;
  margin: 0px auto;
  font-size: 20px;
  line-height: 20px;
  font-weight: bold;
  @media only screen and (max-width: 885px) {
    margin: 15px 0px 90px 0px;
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  width: calc(100% - 10px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: 0px 5px 40px 5px;
  @media only screen and (max-width: 885px) {
    margin: 0px 0px 10px 0px;
    width: 100%;
  }
  &.input-hidden{
    display:none;
  }
`;